import { Grid } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import Carousel from "react-elastic-carousel";
import "./cardStyle.css";
import ProductCard from "./ProductCard";
import Pagination from "./Pagination";
import { useNavigate } from "react-router-dom";
import "./ProductCard2.css";
import Skeleton from "@mui/material/Skeleton";
let PageSize = 12;

const ProductList2 = ({ ProductData, name = "", loading }) => {
  //   const breakPoints = [
  //     { width: 250, itemsToShow: 1 },
  //     { width: 450, itemsToShow: 2 },
  //     { width: 600, itemsToShow: 3 },
  //     { width: 780, itemsToShow: 4 },
  //     { width: 950, itemsToShow: 5 },
  //     { width: 1200, itemsToShow: 6 },
  //   ];

  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = () => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return ProductData.slice(firstPageIndex, lastPageIndex);
  };
  console.log("cur", currentTableData);
  return (
    <div className="ccc">
      <div
        style={{
          float: "left",
          paddingLeft: "20px",
          fontWeight: "bold",
          fontSize: "20px",
        }}
      >
        {name.replace(/%20/g, " ")}
      </div>

      <div style={{ float: "right" }}>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={ProductData.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>

      <Grid container className="productCart2">
        {loading ? (
          <Skeleton variant="rectangular" width={250} height={118} />
        ) : (
          currentTableData().map((product) => (
            <ProductCard key={product.id} product={product} />
          ))
        )}
      </Grid>
    </div>
  );
};

export default ProductList2;
