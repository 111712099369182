import { MDBContainer, MDBIcon } from "mdb-react-ui-kit";
import React from "react";
import './Footer.css';

const SocialMediaConnect = () => {
  return (
    <MDBContainer className=" text-center footer_media">
      <section className="d-flex justify-content-between m-2">
        <div className="text-black">
          <a
            href="https://www.facebook.com/kitablovers"
            target={"_blank"}
            rel="noreferrer"
            className="text-reset"
          >
            <MDBIcon fab className="fab fa-facebook-f fa-2x" />
          </a>
        </div>

        <div className="text-black">
          <a
            href="https://twitter.com/LoversKitab"
            target={"_blank"}
            rel="noreferrer"
            className="text-reset"
          >
            <MDBIcon fab className="fa-twitter fa-2x" />
          </a>
        </div>

        <div className="text-black">
          <a
            href="https://www.instagram.com/kitablovers.official/"
            target={"_blank"}
            rel="noreferrer"
            className="text-reset"
          >
            <MDBIcon fab className="fa-instagram fa-2x" />
          </a>
        </div>
        <div className="text-black">
          <a
            href="https://www.linkedin.com/company/kitablovers/"
            target={"_blank"}
            rel="noreferrer"
            className="text-reset"
          >
            <MDBIcon fab className="fa-linkedin-in fa-2x" />
          </a>
        </div>

        <div className="text-black">
          <MDBIcon fab className="fa-paypal  fa-2x" />
        </div>

        <div className="text-black">
          <MDBIcon fab className="fa-cc-visa  fa-2x" />
        </div>

        <div className="text-black">
          <MDBIcon fab className="fa-cc-mastercard fa-2x" />
        </div>

        <div className="text-black">
          <MDBIcon fab className="fa-cc-discover fa-2x" />
        </div>
      </section>
    </MDBContainer>
  );
};

export default SocialMediaConnect;
