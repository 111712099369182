import { MDBContainer } from "mdb-react-ui-kit";
import Filters from "src/components/Filters/Filters";
import ProductList2 from "src/components/Gallery/ProductList2";
import { Paper } from "@material-ui/core";

const BooksContainer = ({ ProductData, name="",loading}) => {
 
  return (
    <MDBContainer>
      <div className="row mt-4 mb-4">
        <div className="col-md-2 col-sm-12">
          <Filters />
        </div>
        <div className="col-md-10 col-sm-12">
          <Paper style={{ paddingTop: "20px",minHeight:'100vh' }}>
            <ProductList2 ProductData={ProductData} loading={loading} name={name}/>
          </Paper>
        </div>
      </div>
    </MDBContainer>
  );
};

export default BooksContainer;
