import { MDBContainer } from "mdb-react-ui-kit";
import Filters from "src/components/Filters/Filters";
import ProductList from "src/components/Gallery/ProductList";
import { Paper } from "@material-ui/core";

const BooksContainerWithoutFilter = ({ ProductData, name=""}) => {
  return (
    <MDBContainer>
      <div className="row mt-4 mb-4">
        <div className="col-md-12 col-sm-12">
          <Paper style={{ paddingTop: "20px" }}>
            <ProductList ProductData={ProductData} name={name}/>
          </Paper>
        </div>
      </div>
    </MDBContainer>
  );
};

export default BooksContainerWithoutFilter;
