import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import SocialMediaConnect from "./SocialMediaConnect";
import { useNavigate } from "react-router-dom";
import './Footer.css';

export default function Footer() {

  const navigate = useNavigate();

  const scrolToTop = () => {
    window.scrollTo(0,0)
  }

  return (
    <div>
      <MDBFooter
        className="text-center text-lg-start text-muted pt-2 footer_container"
      >
        <section className="" >
          {/* <MDBContainer className="text-center text-md-start mt-8" style={{backgroundColor:"blue",width:"100vw"}}> */}
            <MDBRow className="mt-3  footer_section">
              <MDBCol md="3" lg="4" xl="3" p="1" className="mx-auto mb-4 f_col f_col-1" >
                <h3 className="footer_heading text-uppercase text-white mb-4">
                  <MDBIcon icon="gem" className="me-3" />
                  Our Company
                </h3>
                <p className="text-white pe-2">
                  We would like to thank you for shopping with us. You can write
                  us for any new thoughts at “cs@kitablovers.com” helping us to
                  improvise for the reader satisfaction. We offer huge
                  collection of New & Preloved-books in diverse category of
                  Fiction, Non-fiction, Biographies, History, Teen fiction, Self
                  -Help, Children...
                </p>
              </MDBCol>

              <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4 f_col f_col-2" >
                <h3 className="footer_heading text-uppercase text-white  mb-4">Contact Us</h3>
                <p className="text-white">
                  <MDBIcon icon="map-marked-alt" className="me-2" />
                  <a href="#!" className="text-reset text-white">
                    Kh no 20/29, Nangli poona village, Delhi - 110036
                  </a>
                </p>
                <p className="text-white">
                  <MDBIcon icon="headset" className="me-3" />
                  <a href="#!" className="text-reset">
                    +91-8860-525-525
                  </a>
                </p>
                <p className="text-white">
                  <MDBIcon icon="at" className="me-3" />
                  <a href="#!" className="text-reset">
                    cs@kitablovers.com
                  </a>
                </p>
              </MDBCol>

              <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4 f_col f_col-3" >
                <h3 className="footer_heading text-uppercase  mb-4 text-white">Service</h3>
                <p className="text-white">
                  <MDBIcon icon="user" className="me-2" />
                  <a href="#!" className="text-reset">
                    My Account
                  </a>
                </p>
                <p className="text-white">
                  <MDBIcon icon="shopping-cart" className="me-2" />
                  <a href="#!" className="text-reset">
                    Checkout
                  </a>
                </p>
                <p className="text-white">
                  <MDBIcon icon="shopping-bag" className="me-2" />
                  <a href="#!" className="text-reset">
                    Shop Now
                  </a>
                </p>
                <p className="text-white">
                  <MDBIcon icon="history" className="me-2" />
                  <a href="#!" className="text-reset">
                    Order History
                  </a>
                </p>
                <p className="text-white">
                  <MDBIcon icon="question" className="me-2" />
                  <a href="#!" className="text-reset">
                    FAQs
                  </a>
                </p>
              </MDBCol>

              <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4 f_col f_col-4" >
                <h3 className="footer_heading text-uppercase  mb-4 text-white">policy</h3>
                <p className="text-white">
                  <a href="#!" className="text-reset">
                    About us
                  </a>
                </p>
                <p className="text-white">
                  <a href="#!" className="text-reset">
                    Privacy Policy
                  </a>
                </p>
                <p className="text-white">
                  <a href="#!" className="text-reset">
                    Return Policy
                  </a>
                </p>
                <p className="text-white">
                  <a href="#!" className="text-reset">
                    Contact Us
                  </a>
                </p>
              </MDBCol>

              <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4 f_col f_col-5" >
                <h3 className="footer_heading text-uppercase  mb-4 text-white">Quick Links</h3>
                <p className="text-white">
                  <a href="#/new-arrivals" className="text-reset footerLink" onClick={scrolToTop()}>
                  New Arrivals
                  </a>
                </p>
                <p className="text-white">
                  <a href="#/best-sellers" className="text-reset footerLink">
                  Best Sellers
                  </a>
                </p>
                <p className="text-white">
                  <a href="#/book-sets" className="text-reset footerLink">
                  Book Sets
                  </a>
                </p>
                <p className="text-white">
                  <a href="#/merchandise" className="text-reset footerLink">
                  Merchandise
                  </a>
                </p>
                <p className="text-white">
                  <a href="#/load-box" className="text-reset footerLink">
                  Load the Box
                  </a>
                </p>
              </MDBCol>

            </MDBRow>
          {/* </MDBContainer> */}
        </section>
      </MDBFooter>
      <SocialMediaConnect />
      <div
        className="text-center p-1 text-dark copyright"
        style={{ backgroundColor: "#ffc919" }}
      >
        © 2023 Copyright:
        <a className="text-reset " href="https://kitablovers.com/">
          kitablovers.com
        </a>
      </div>
    </div>
  );
}
