import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import FavoriteIcon from "@mui/icons-material/Favorite";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Box,
  Paper,
  Grid,
} from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    margin: "15px",
    // justifyContent:"spaceEvenly",
    // margin:"auto",
    height: "auto",
    width: "250px",
    position: "relative",
    alignContent: "center",
    alignItems: "center",
    "&:hover": {
      "& $contentDetails": {
        top: "40%",
        opacity: 1,
      },
      "& $media": {
        opacity: 0.5,
      },
      "& $offer": {
        opacity: 0.5,
      },
    },
  },
  media: {
    height: "200px",
    backgroundSize: "contain",
  },
  content: {
    height: "auto",
    alignContent: "top",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  offer: {
    fontSize: "10px",
    backgroundColor: "black",
    color: "white",
    marginRight: "5px",
    marginTop: "5px",
    padding: theme.spacing(0.7),
    position: "absolute",
    top: theme.spacing(0),
    right: theme.spacing(0),
    borderRadius: "100px",
    zIndex: 1,
  },
  price: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "black",
    textAlign: "left",
  },
  priceDeprecated: {
    fontSize: "14px",
    marginLeft: "10px",
    color: "#959595",
    textDecoration: "line-through",
  },

  priceDetails: {
    display: "flex",
    marginTop: "10px",
    textAlign: "left",
    flexDirection: "row",
  },
  productDetails: {
    display: "flex",
    fontSize: "17px",
    fontWeight: "bold",
    flexDirection: "row",
  },

  contentDetails: {
    position: "absolute",
    textAlign: "center",
    paddingLeft: "1em",
    paddingRight: "1em",
    width: "100%",
    top: "0%",
    left: "50%",
    opacity: "0",
    transform: "translate(-50%, -50%)",
    transition: "all 0.3s ease-in-out 0s",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
}));

// const navigate = useNavigate()

const ProductCard = ({ product }) => {
  const classes = useStyles();

  const navigate = useNavigate();

  const navigateToDetailPage = () => {
    navigate(`/pageDetail/${product.id}`);
  };
  console.log("sd", product);
  return (
    <Card className={classes.root} style={{}} onClick={() => {}}>
      <Grid>
        <Grid item xs={12} sm={12}>
          <CardMedia
            className={classes.media}
            image={`data:image/png;base64,${product.thumbnail}`}
            title="Product Image"
          />
          <CardContent className={classes.content} style={{ padding: "10px" }}>
            <Typography className={classes.offer} variant="subtitle2">
              {product.offer}
            </Typography>
            <Typography className={classes.productDetails} component="h2">
              {product.title}
            </Typography>
            <div className={classes.priceDetails}>
              <Typography className={classes.price} variant="h6" component="p">
                {product.price}
              </Typography>
              <Typography
                className={classes.priceDeprecated}
                variant="h6"
                component="p"
              >
                {product.mrp}
              </Typography>
            </div>
            <div className={classes.contentDetails}>
              <ShoppingCartRoundedIcon
                variant="contained"
                style={{ color: "black" }}
              >
                Add to Cart
              </ShoppingCartRoundedIcon>
              <FavoriteIcon variant="contained" style={{ color: "red" }}>
                Buy Now
              </FavoriteIcon>
            </div>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ProductCard;
