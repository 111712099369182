import { Divider, Paper, Typography } from '@material-ui/core'
import React from 'react'
import EventsItem from './EventsItem'
import './EventStyle.css'

const PastEvents = () => {
  return (
    <Paper className='pb-2'>
      <Typography className='event-heading'>PAST EVENTS</Typography>
      <Divider />
      <EventsItem />
    </Paper>
  )
}

export default PastEvents