import { Button, ButtonGroup, Divider } from '@material-ui/core'
import { CardContent, CardMedia, Grid } from '@material-ui/core'
import { ShoppingCartOutlined } from '@mui/icons-material'
import React from 'react'
import { Stack } from 'react-bootstrap'

const GoCart = () => {
    return (
        <>
            <Grid container style={{width:"80%", backgroundColor:"lightgrey", margin:"auto",height:"80vh",alignItems:"center"}}>
                <Grid item xs={12} sm={4}>
                    <img src="https://m.media-amazon.com/images/G/31/cart/empty/kettle-desaturated._CB424694257_.svg" />
                </Grid>
                <Grid item xs={12} sm={8} style={{padding:"50px"}} >
                    <Stack >
                        <h1>Your cart is empty</h1>
                        <p style={{ }} className="text-muted ">Please shop now</p>
                    </Stack>
                    <Divider style={{ marginBottom: "15px", height: "1.5px", marginTop: "15px" }} />

                <ButtonGroup >
                  <Button variant="contained"  className="cart-btn" style={{
                    borderRadius: 5, backgroundColor: "#ffc919", height: "50px", fontWeight: "bold",
                    marginRight:"10px"
                  }}>
                    Sign in to your account
                  </Button>
                  <Button variant="outlined"  style={{ height: "50px",
                    borderRadius: 5, fontWeight: "bold"
                  }}>
                    Sign up now
                  </Button>
                </ButtonGroup>
                </Grid>
            </Grid>
        </>
    )
}

export default GoCart