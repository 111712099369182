import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import React from 'react'
import './EventStyle.css'

const EventsItem = () => {
    return (
        <Card className='event-box' style={{
            border: "0.1px solid lightgrey"
            // boxShadow:"2px 3px 15px 0 rgba(0,0,0,0.2) ,2px 3px 15px 0 rgba(0,0,0,0.2)"
        }}>
            <Typography className='event-item-date'>Feb. 18, 2023 - Feb. 26, 2023</Typography>
            <Typography className='event-item-title'>LOAD THE BOX : India's Largest Book Sale in INDORE</Typography>
            <Typography className='event-item-venue'>Venue : Gandhi Hall, 51, Mahatma Gandhi Rd, Shastri Market, New Siyaganj,</Typography>
            <Typography className='event-item-desc'>Venue stri Market, New Siyaganj,Venue : Gandhi Hall, 51, Mahatma Gandhi Rd, Shastri Market, New Siyaganj,Venue : Gandhi Hall, 51, Mahatma Gandhi Rd, Shastri Market</Typography>
        </Card >
    )
}

export default EventsItem