import React, { Component, Suspense } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import Footer from "./layout/Footer/Footer";
import Header from "./layout/Header/Header";
import "./scss/style.scss";
import NewArrivals from "./views/user/NewArrivals/NewArrivals";
import Login from "./views/pages/login/Login";
import Register from "./views/pages/register/Register";
import Page404 from "./views/pages/page404/Page404";
import Page500 from "./views/pages/page500/Page500";
import BestSeller from "./views/user/BestSeller/BestSeller";
import BookSets from "./views/user/BookSets/BookSets";
import Merchandise from "./views/user/Merchandise/Merchandise";
import ProductDetailPage from "./components/ProductDetailPage/ProductDetailPage";
import GoCart from "./layout/Header/GoCart";
import ShoppingCartPage from "./layout/Header/ShoppingCartPage";
import Genre from "./views/user/Genre/Genre";
import LoadBoxMain from "./components/LoadBox/LoadBoxMain";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));
const LandingPage = React.lazy(() =>
  import("./views/user/LandingPage/LandingPage")
);

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route
              exact
              path="/login"
              name="Login Page"
              element={
                <>
                  <Header />
                  <Login />
                  <Footer />
                </>
              }
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              element={
                <>
                  <Header />
                  <Register />
                  <Footer />
                </>
              }
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              element={
                <>
                  <Header />
                  <Page404 />
                  <Footer />
                </>
              }
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              element={
                <>
                  <Header />
                  <Page500 />
                  <Footer />
                </>
              }
            />
            <Route
              exact
              path="/"
              name="landing page"
              element={
                <>
                  <Header />
                  <LandingPage />
                  <Footer />
                </>
              }
            />
            <Route
              exact
              path="/new-arrivals"
              name="new arrivals"
              element={
                <>
                  <Header />
                  <NewArrivals />
                  <Footer />
                </>
              }
            />
            <Route
              exact
              path="/best-sellers"
              name="best sellers page"
              element={
                <>
                  <Header />
                  <BestSeller />
                  <Footer />
                </>
              }
            />
            <Route
              exact
              path="/book-sets"
              name="book sets page"
              element={
                <>
                  <Header />
                  <BookSets />
                  <Footer />
                </>
              }
            />
            <Route
              exact
              path="/merchandise"
              name="merchandise page"
              element={
                <>
                  <Header />
                  <Merchandise />
                  <Footer />
                </>
              }
            />
            <Route
              exact
              path="/genre/:name"
              name="genre page"
              element={
                <>
                  <Header />
                  <Genre />
                  <Footer />
                </>
              }
            />
            <Route path="*" name="Home" element={<DefaultLayout />} />
            <Route
              path="/pageDetail/:id"
              name="Detail Page"
              element={
                <>
                  <Header />
                  <ProductDetailPage />
                  <Footer />
                </>
              }
            />
            <Route path="/goCart" name="go cart page" element={
              <>
                <Header />
                <GoCart />
                <Footer />
              </>
            } />
            <Route path="/shoppingCart" name="shopping cart page" element={
              <>
                <Header />
                {/* <ShoppingCartPage/> */}
                <GoCart />
                <Footer />
              </>
            } />

            <Route
              exact
              path="/load-box"
              name="Load box page"
              element={
                <>
                  <Header />
                  <LoadBoxMain />
                  <Footer />
                </>
              }
            />

          </Routes>
        </Suspense>
      </HashRouter>
    );
  }
}

export default App;
