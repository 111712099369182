import { Divider, Paper, Typography } from '@material-ui/core'
import React from 'react'
import EventsItem from './EventsItem'
import './EventStyle.css'
import { ProductData } from "./MockEvents";

const UpcomingEvents = () => {
  return (
    <Paper className='pb-2 upEvents' >
      <Typography className='event-heading'>UPCOMMING EVENTS</Typography>
      <Divider />
      {ProductData.map((item) => <EventsItem />)}
    </Paper>
  )
}

export default UpcomingEvents