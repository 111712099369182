import { Card } from '@material-ui/core'
import { MDBCard, MDBContainer } from 'mdb-react-ui-kit'
import React from 'react'
import PastEvents from './PastEvents'
import UpcomingEvents from './UpcomingEvents'

const LoadBoxMain = () => {
    return (
        <>
            <MDBContainer className="mt-5 mb-5">
                <UpcomingEvents />
            </MDBContainer>
            <MDBContainer className="mb-5">
                <PastEvents />
            </MDBContainer>
        </>
    )
}

export default LoadBoxMain