import { Check } from "@mui/icons-material";
import {
  Card,
  CardActions,
  CardContent,
  Typography,
  Button,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React from "react";

const Filters = () => {


  return (
    <div>
      <Typography variant="h4" className="mb-1">
        Filters
      </Typography>
      <div className="mb-1">
        <Divider
          variant="fullWidth"
          className="bg-secondary"
          textAlign="center"
          sx={{ height: 2.5, marginBottom: 2 }}
        />
      </div>
      {/* Price */}
      <Card>
        <CardContent>
          <Typography
            variant="h6"
            color="text"
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Price
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox size="8px" style={{ padding: "5px 5px 5px 10px" }} />
              }
              label={
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#5A5A5A",
                  }}
                >
                  0-100
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox size="8px" style={{ padding: "5px 5px 5px 10px" }} />
              }
              label={
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#5A5A5A",
                  }}
                >
                  100-500
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox size="8px" style={{ padding: "5px 5px 5px 10px" }} />
              }
              label={
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#5A5A5A",
                  }}
                >
                  500-1000
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox size="8px" style={{ padding: "5px 5px 5px 10px" }} />
              }
              label={
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#5A5A5A",
                  }}
                >
                  1000-3000
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox size="8px" style={{ padding: "5px 5px 5px 10px" }} />
              }
              label={
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#5A5A5A",
                  }}
                >
                  Above 3000
                </Typography>
              }
            />
          </FormGroup>
        </CardContent>
      </Card>
      {/* Conditions */}
      <Card className="mt-4">
        <CardContent>
          <Typography
            variant="h6"
            color="text"
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Conditions
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox size="8px" style={{ padding: "5px 5px 5px 10px" }} />
              }
              label={
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#5A5A5A",
                  }}
                >
                  New
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox size="8px" style={{ padding: "5px 5px 5px 10px" }} />
              }
              label={
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#5A5A5A",
                  }}
                >
                  Almost New
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox size="8px" style={{ padding: "5px 5px 5px 10px" }} />
              }
              label={
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#5A5A5A",
                  }}
                >
                  Good
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox size="8px" style={{ padding: "5px 5px 5px 10px" }} />
              }
              label={
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#5A5A5A",
                  }}
                >
                  Readable
                </Typography>
              }
            />
          </FormGroup>
        </CardContent>
      </Card>
      {/* Bindings */}
      <Card className="mt-4">
        <CardContent>
          <Typography
            variant="h6"
            color="text"
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Bindings
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox size="8px" style={{ padding: "5px 5px 5px 10px" }} />
              }
              label={
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#5A5A5A",
                  }}
                >
                  Paperback
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox size="8px" style={{ padding: "5px 5px 5px 10px" }} />
              }
              label={
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#5A5A5A",
                  }}
                >
                  Hardcover
                </Typography>
              }
            />
          </FormGroup>
        </CardContent>
      </Card>
      {/* Age group */}
      <Card className="mt-4">
        <CardContent>
          <Typography
            variant="h6"
            color="text"
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Age Group
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox size="8px" style={{ padding: "5px 5px 5px 10px" }} />
              }
              label={
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#5A5A5A",
                  }}
                >
                  0-2 years
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox size="8px" style={{ padding: "5px 5px 5px 10px" }} />
              }
              label={
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#5A5A5A",
                  }}
                >
                  2-5 years
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox size="8px" style={{ padding: "5px 5px 5px 10px" }} />
              }
              label={
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#5A5A5A",
                  }}
                >
                  5-9 years
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox size="8px" style={{ padding: "5px 5px 5px 10px" }} />
              }
              label={
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#5A5A5A",
                  }}
                >
                  9-12 years
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox size="8px" style={{ padding: "5px 5px 5px 10px" }} />
              }
              label={
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#5A5A5A",
                  }}
                >
                  Teens
                </Typography>
              }
            />
          </FormGroup>
        </CardContent>
      </Card>
      {/* Languages */}
      <Card className="mt-4 mb-4">
        <CardContent>
          <Typography
            variant="h6"
            color="text"
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Language
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox size="8px" style={{ padding: "5px 5px 5px 10px" }} />
              }
              label={
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#5A5A5A",
                  }}
                >
                  English
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox size="8px" style={{ padding: "5px 5px 5px 10px" }} />
              }
              label={
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#5A5A5A",
                  }}
                >
                  Hindi
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox size="8px" style={{ padding: "5px 5px 5px 10px" }} />
              }
              label={
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#5A5A5A",
                  }}
                >
                  French
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox size="8px" style={{ padding: "5px 5px 5px 10px" }} />
              }
              label={
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#5A5A5A",
                  }}
                >
                  German
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox size="8px" style={{ padding: "5px 5px 5px 10px" }} />
              }
              label={
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#5A5A5A",
                  }}
                >
                  Bengali
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox size="8px" style={{ padding: "5px 5px 5px 10px" }} />
              }
              label={
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#5A5A5A",
                  }}
                >
                  Tamil
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox size="8px" style={{ padding: "5px 5px 5px 10px" }} />
              }
              label={
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#5A5A5A",
                  }}
                >
                  Telugu
                </Typography>
              }
            />
          </FormGroup>
        </CardContent>
      </Card>
    </div>
  );
};

export default Filters;
