import axios from "axios";

const client = axios.create({
  baseURL: "https://kitablovers.onrender.com/api",
});

// User Panel APIs
export const getCrimeData = async () => {
  try {
    const res = await client.get("/book/crime");
    return res.data.resp;
  } catch (e) {
    return e;
  }
};
