export const ProductData = [
    {
        "_id": "641b29a302a3f90bc61f4c6c",
        "address": "235 Abbey Court, Freelandville, Missouri, 9820",
        "banner": "514 Seton Place, Fairmount, Colorado, 9308",
        "category_desc": "629 Hegeman Avenue, Indio, District Of Columbia, 7714",
        "category_name": "934 Bassett Avenue, Hasty, South Carolina, 501",
        "category_priority": 2,
        "category_seo_name": "952 Chestnut Avenue, Courtland, Hawaii, 1031",
        "city": "OVIUM",
        "category_type": "VIDTO",
        "created_at": "2018-04-16T07:21:29 -06:-30",
        "updated_at": "2014-11-18T04:00:38 -06:-30"
    },
    {
        "_id": "641b29a3ab4b67432f058e43",
        "address": "263 Commerce Street, Keyport, Nebraska, 4767",
        "banner": "382 Banner Avenue, Grapeview, Puerto Rico, 659",
        "category_desc": "101 Ovington Court, Moraida, West Virginia, 5189",
        "category_name": "999 Montana Place, Sparkill, Michigan, 2185",
        "category_priority": 4,
        "category_seo_name": "370 Manhattan Avenue, Laurelton, Rhode Island, 7161",
        "city": "MAXIMIND",
        "category_type": "EMTRAC",
        "created_at": "2021-06-07T03:23:54 -06:-30",
        "updated_at": "2021-11-29T01:18:08 -06:-30"
    },
    {
        "_id": "641b29a3f345208aa5693700",
        "address": "187 Carlton Avenue, Bangor, Arizona, 4887",
        "banner": "967 Division Place, Avoca, Northern Mariana Islands, 1751",
        "category_desc": "176 Dennett Place, Greenbush, Massachusetts, 3460",
        "category_name": "265 Story Court, Jamestown, Wyoming, 8745",
        "category_priority": 4,
        "category_seo_name": "312 Virginia Place, Genoa, Maine, 3744",
        "city": "KINETICA",
        "category_type": "WAAB",
        "created_at": "2017-06-12T05:14:56 -06:-30",
        "updated_at": "2021-05-09T11:29:21 -06:-30"
    },
    {
        "_id": "641b29a3a968b8c5d3931557",
        "address": "572 Nolans Lane, Glenshaw, Federated States Of Micronesia, 9632",
        "banner": "201 Hendrix Street, Allensworth, Minnesota, 6910",
        "category_desc": "228 Suydam Place, Lumberton, New York, 845",
        "category_name": "901 Berkeley Place, Gloucester, Pennsylvania, 2638",
        "category_priority": 1,
        "category_seo_name": "227 Stockholm Street, Fulford, Kentucky, 9490",
        "city": "ECLIPTO",
        "category_type": "UTARA",
        "created_at": "2019-06-06T11:44:08 -06:-30",
        "updated_at": "2017-12-11T07:32:53 -06:-30"
    },
    {
        "_id": "641b29a380866431e3882b17",
        "address": "534 Montague Terrace, Hartsville/Hartley, New Mexico, 7275",
        "banner": "485 Seeley Street, Virgie, Utah, 7307",
        "category_desc": "679 Vandervoort Avenue, Alfarata, Illinois, 6196",
        "category_name": "984 Covert Street, Bordelonville, Ohio, 2929",
        "category_priority": 1,
        "category_seo_name": "994 Wolcott Street, Spelter, Washington, 6539",
        "city": "QUARX",
        "category_type": "IDETICA",
        "created_at": "2019-01-05T12:28:15 -06:-30",
        "updated_at": "2020-04-18T06:22:42 -06:-30"
    }
]