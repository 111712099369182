import {
  AppBar,
  Avatar,
  Box,
  Menu,
  MenuItem,
  styled,
  Toolbar,
  Drawer,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import WalletIcon from "@mui/icons-material/Wallet";
import React, { useState } from "react";
import { Logout, Person } from "@mui/icons-material";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import WidgetsIcon from "@mui/icons-material/Widgets";
import ListIcon from "@mui/icons-material/List";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./btnEffect.css";
import { Badge } from "@material-ui/core";

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#ffc919",
    },
  },
});
const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [product, setProduct] = useState({});

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState(0);
  const [openCategory, setOpenCategory] = React.useState(null);

  const genreActive = location.pathname.includes("/genre/") ? true: false

  const goCart = () => {
    navigate("/goCart");
  };

  const GoShoppingCart = () => {
    navigate("/shoppingCart");
  };

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }
  function Category(e) {
    if (openCategory !== e.currentTarget) {
      setOpenCategory(e.currentTarget);
    }
  }
  function handleClose() {
    setAnchorEl(null);
    setOpenCategory(null);
  }

  function handleItemClick(genre_name) {
    setAnchorEl(null);
    setOpenCategory(null);
    navigate(`/genre/${genre_name}`);
    setIsDrawerOpen(false);
    setIsGenreDrawerOpen(false);
  }

  const categoryList = [
    {
      name: "Crime",
    },
    {
      name: "Romance",
    },
    {
      name: "Young Adult",
    },
    {
      name: "Non Fiction",
    },
    { name: "Kids" },
    { name: "Teen Fiction" },
    { name: "Coffee Table" },
    { name: "Graphics Novels" },
    {name:"Show all"}
  ];
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isGenreDrawerOpen, setIsGenreDrawerOpen] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="sticky" style={{ background: '#ffc919' }}>
        <StyledToolbar>
          <Box sx={{ ml: { lg: 4, md: 4, sm: 3, xs: 0 }, pt: 0.5 }}>
            <img
              role="button"
              onClick={() => navigate("/")}
              src="https://kitablovers.com/front/images/logo/logo.png"
              width="65"
              alt="kitab lovers logo"
            ></img>
          </Box>
          <Paper
            component="form"
            sx={{
              p: " 0.5px",
              display: "flex",
              alignItems: "center",
              width: { md: 700, sm: 400, xs: 200 },
            }}
          >
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1, width: 300 }}
              placeholder="Search...."
              inputProps={{ "aria-label": "search...." }}
            />
          </Paper>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box>
              <PersonIcon
                fontSize="large"
                sx={{
                  cursor: "pointer",
                  mt: 0.5,
                  alignItems: "center",
                  display: { xs: "none", sm: "none", md: "none", lg: "block" },
                }}
                aria-owns={anchorEl ? "simple-menu" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                onMouseOver={handleClick}
              />
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{ onMouseLeave: handleClose }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  onClick={() => navigate("/login")}
                  sx={{ width: 300 }}
                >
                  <div className="mx-1">
                    {" "}
                    <Person />{" "}
                  </div>
                  LOGIN / SIGNUP
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => navigate("/admin")}>
                  <div className="mx-1">
                    <ShoppingCartIcon />
                  </div>
                  Your Orders
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  {" "}
                  <div className="mx-1">
                    <FavoriteIcon />
                  </div>
                  Your Wishlist
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <div className="mx-1">
                    <WalletIcon />
                  </div>
                  Your Wallet
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <div className="mx-1">
                    <KeyboardReturnIcon />
                  </div>
                  Your Return
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  {" "}
                  <div className="mx-1">
                    <RequestPageIcon />
                  </div>
                  Requests
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClose}>
                  {" "}
                  <Logout fontSize="small" /> SIGNOUT
                </MenuItem>
              </Menu>
              {/* Category */}
              <Menu
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3,1fr)",
                }}
                id="category_menu"
                anchorEl={openCategory}
                open={Boolean(openCategory)}
                onClose={handleClose}
                MenuListProps={{ onMouseLeave: handleClose }}
                PaperProps={{
                  elevation: 0,
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    width: "68%",
                    paddingBottom: "10px",
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                  },
                }}
                // transformOrigin={{ horizontal: "center", vertical: "top" }}
                // anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
              >
                {categoryList.map((el) => (
                  <MenuItem
                    style={{ float: "left", width: "33%" }}
                    key={el.name}
                    onClick={() => handleItemClick(el.name)}
                  >
                    {/* <NavLink
                      to="/genre/${el.name}"
                      className={({ isActive }) =>
                        isActive ? "activeClass btn_effect" : "text-white"
                      }
                    >
                      <Button
                        className="text-black btn_effect"
                        // onClick={() => navigate("/new-arrivals")}
                      >
                        {el.name}
                      </Button>
                    </NavLink> */}
                    {el.name}
                  </MenuItem>
                ))}
              </Menu>

              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{
                  display: { xs: "none", sm: "none", md: "none", lg: "block" },
                  cursor: "pointer",
                }}
              >
                Profile
              </Typography>
            </Box>
            <Box
              sx={{
                ml: 2,
                mt: 0.5,
                display: { xs: "none", sm: "none", md: "none", lg: "block" },
              }}
            >
              <FavoriteIcon fontSize="large" role="button" />
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                mr="2px"
                role="button"
              >
                Wishlist
              </Typography>
            </Box>
            <Box
              style={{ position: "relative" }}
              onClick={GoShoppingCart}
              sx={{
                ml: 2,
                mt: 0.5,
                display: { xs: "none", sm: "none", md: "none", lg: "block" },
              }}
            >
              <Badge badgeContent="0" color="secondary">
                <ShoppingCartIcon fontSize="large" role="button" />
              </Badge>
              <Typography variant="subtitle2" fontWeight="bold" role="button">
                Cart
              </Typography>
            </Box>
          </Box>
        </StyledToolbar>
      </AppBar>
      <Box
        sx={{
          backgroundColor: "#212529",
          height: 40,
          //display: "flex",
          color: "#FAF5E9",
          display: { xs: "none", sm: "none", md: "none", lg: "flex" },
          justifyContent: "center",
        }}
      >
        <Button
          // className="text-white btn_effect"
          className={genreActive ? "activeClass btn_effect" : "text-white"}
          onClick={Category}
          onMouseOver={Category}
          aria-owns={openCategory ? "category_menu" : undefined}
          aria-haspopup="true"
        >
          Genre
        </Button>

        <div className="vr "></div>
        <NavLink
          to="/new-arrivals"
          className={({ isActive }) =>
            isActive ? "activeClass btn_effect" : "text-white"
          }
        >
          <Button
            className="text-white btn_effect"
            // onClick={() => navigate("/new-arrivals")}
          >
            New Arrivals
          </Button>
        </NavLink>

        <div className="vr "></div>

        <NavLink
          to="/best-sellers"
          className={({ isActive }) =>
            isActive ? "activeClass btn_effect" : "text-white"
          }
        >
          <Button
            className="text-white btn_effect"
            onClick={() => navigate("/best-sellers")}
          >
            Best Sellers
          </Button>
        </NavLink>
        <div className="vr "></div>

        <NavLink
          to="/book-sets"
          className={({ isActive }) =>
            isActive ? "activeClass btn_effect" : "text-white"
          }
        >
          <Button
            className="text-white btn_effect"
            onClick={() => navigate("/book-sets")}
          >
            {" "}
            Book Sets
          </Button>
        </NavLink>
        <div className="vr "></div>

        <NavLink
          to="/merchandise"
          className={({ isActive }) =>
            isActive ? "activeClass btn_effect" : "text-white"
          }
        >
          <Button
            className="text-white btn_effect"
            onClick={() => navigate("/merchandise")}
          >
            {" "}
            Merchandise
          </Button>
        </NavLink>
        <div className="vr "></div>

        <NavLink
          to="/load-box"
          className={({ isActive }) =>
            isActive ? "activeClass btn_effect" : "text-white"
          }
        >
          <Button
            className="text-white btn_effect"
            onClick={() => navigate("/load-box")}
          >
            {" "}
            Load the Box
          </Button>
        </NavLink>
        <div className="vr "></div>

        {/* <NavLink to="/wholesale" className={({ isActive }) => isActive ? 'activeClass btn_effect' : 'text-white'}> */}
        <Button className="text-white btn_effect">Wholesale</Button>
        {/* </NavLink> */}
        <div className="vr "></div>
        {/* <NavLink to="/book-requests" className={({ isActive }) => isActive ? 'activeClass btn_effect' : 'text-white'}> */}
        <Button className="text-white btn_effect"> Book Requests</Button>
        {/* </NavLink> */}
        <div className="vr "></div>
      </Box>
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 999,
          display: { xs: "block", sm: "block", md: "none", lg: "none" },
        }}
        elevation={15}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction
            label="Menu"
            icon={<WidgetsIcon />}
            onClick={() => setIsDrawerOpen(true)}
          />
          <Drawer
            anchor="left"
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
          >
            <Box
              sx={{
                width: "100vw",
                height: 70,
                bgcolor: "#ffc919",
                display: "flex",
              }}
            >
              <Typography
                color="black"
                variant="h4"
                fontWeight="bold"
                className="menuToggle"
              >
                <h1>Menu</h1>

                <i
                  className="fa-solid fa-regular fa-xmark toggleIcon"
                  onClick={() => setIsDrawerOpen(false)}
                ></i>
              </Typography>
            </Box>
            <Box
              sx={{
                height: 700,
              }}
            >
              <NavLink to="/new-arrivals">
                <MenuItem
                  className="genreList"
                  onClick={() => setIsDrawerOpen(false)}
                >
                  New Arrivals
                </MenuItem>
              </NavLink>
              <NavLink to="/best-sellers">
                <MenuItem
                  className="genreList"
                  onClick={() => setIsDrawerOpen(false)}
                >
                  Best Sellers
                </MenuItem>
              </NavLink>
              <NavLink to="/book-sets">
                <MenuItem
                  className="genreList"
                  onClick={() => setIsDrawerOpen(false)}
                >
                  Book Sets
                </MenuItem>
              </NavLink>
              <NavLink to="/merchandise">
                <MenuItem
                  className="genreList"
                  onClick={() => setIsDrawerOpen(false)}
                >
                  Merchandise
                </MenuItem>
              </NavLink>
              <NavLink to="/load-box">
                <MenuItem
                  className="genreList"
                  onClick={() => setIsDrawerOpen(false)}
                >
                  Load the Box
                </MenuItem>
              </NavLink>

              <MenuItem
                className="genreList"
                onClick={() => setIsDrawerOpen(false)}
              >
                Book Requests
              </MenuItem>
            </Box>
          </Drawer>

          <BottomNavigationAction
            label="Genre"
            icon={<ListIcon />}
            onClick={() => setIsGenreDrawerOpen(true)}
          />
          <Drawer
            anchor="left"
            open={isGenreDrawerOpen}
            onClose={() => setIsGenreDrawerOpen(false)}
          >
            <Box
              sx={{
                width: "100vw",
                height: 70,
                bgcolor: "#ffc919",
                display: "flex",
                justifyContent: "left",
                alignItems: "left",
              }}
            >
              <Typography
                color="black"
                variant="h4"
                fontWeight="bold"
                className="menuToggle"
              >
                <h1>Genre</h1>

                <i
                  className="fa-solid fa-regular fa-xmark toggleIcon"
                  onClick={() => setIsGenreDrawerOpen(false)}
                ></i>
              </Typography>
            </Box>
            <Box
              sx={{
                height: 700,
              }}
            >
              {categoryList.map((el) => (
                <MenuItem
                  className="genreList"
                  style={{ width: "100%" }}
                  key={el.name}
                  onClick={() => handleItemClick(el.name)}
                >
                  {el.name}
                </MenuItem>
              ))}
            </Box>
          </Drawer>
          <BottomNavigationAction
            label="Account"
            icon={<AccountCircleIcon />}
          />

          <BottomNavigationAction label="Cart" icon={<ShoppingCartIcon />} />
          <BottomNavigationAction label="Wishlist" icon={<FavoriteIcon />} />
        </BottomNavigation>
      </Paper>
    </ThemeProvider>
  );
};

export default Header;
