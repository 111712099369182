import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BooksContainer from "src/components/BooksContainer";
import { ProductData } from "src/components/Gallery/ProductData";
import { getCrimeData } from "src/services/http.userService";

const Genre = () => {
  const location = useLocation();
  const name = location.pathname.split("/")[2];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    switch (name) {
      case "Crime": {
        getCrimeData()
          .then((res) => {
            if (res) {
              setData(res);
              setLoading(false);
            }
          })
          .catch((err) => {
            console.log("err", err);
            setLoading(false);
          });
      }
    }
  }, []);
  return <BooksContainer ProductData={data} loading={loading} name={name} />;
};

export default Genre;
