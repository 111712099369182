import React, { useState } from "react";
import { Typography, Button } from "@material-ui/core";
import { Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import "./product_detail_style.css";

const LongText = ({ text }) => {
    const [showFullText, setShowFullText] = useState(false);

    const toggleFullText = () => {
        setShowFullText(!showFullText);
    };

    return (
        <div>
            <Typography variant="body1" component="div" style={{ overflow: "hidden", maxHeight: showFullText ? "none" : 50 }} className="text-muted">
                {text}
            </Typography>
            {!showFullText && (
                <Stack direction="row" spacing={2}>
                    <Button className="view-more-less-btn" onClick={toggleFullText} startIcon={<ExpandMoreIcon />}
                        style={{
                            padding: 0,
                            textDecoration: "none",
                            textTransform: "none",
                        }}>
                        <Typography className="view-more-less">Read more</Typography>
                    </Button>
                </Stack>)}
            {
                showFullText && (
                    <Stack direction="row" spacing={2}>
                        <Button className="view-more-less-btn" onClick={toggleFullText} startIcon={<ExpandLessIcon />}
                            style={{
                                padding: 0,
                                textDecoration: "none",
                                textTransform: "none",
                            }}>
                            <Typography className="view-more-less">Read less</Typography>
                        </Button>
                    </Stack>
                )
            }
        </div >
    );
};

export default LongText;
