export const ProductData = [
  {
    id: 1123,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server-click",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 2,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 3,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 4,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 5,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 6,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 7,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 8,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 9,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 10,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 11,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "End1",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 12,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "End2",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 1,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "page 11",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 2,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "page 12",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 3,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 4,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 5,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 6,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 7,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 8,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 9,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 10,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 11,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "End1",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 12,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "End2",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 1,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 2,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 3,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 4,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 5,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 6,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 7,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 8,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 9,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 10,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 11,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "End1",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 12,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "End2",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 1,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 2,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 3,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 4,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 5,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 6,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 7,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 8,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 9,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 10,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 11,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "End1",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 12,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "End2",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 1,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 2,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 3,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 4,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 5,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 6,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 7,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 8,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 9,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 10,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 11,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "End1",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 12,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "End2",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 1,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 2,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 3,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 4,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 5,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 6,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 7,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 8,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 9,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 10,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 11,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "End1",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 12,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "End2",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 1,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 2,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 3,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 4,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 5,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 6,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 7,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 8,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 9,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 10,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "json-server",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 11,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "End1",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
  {
    id: 12,
    image: "https://media.istockphoto.com/photos/concept-picture-id1154231467",
    title: "End2",
    desc: "typicode",
    price: "$255",
    offer: "25%",
    oldPrice: "$359",
  },
];
