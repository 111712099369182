import { Typography, Button, Card, CardContent, CardMedia, makeStyles, IconButton, Paper, Grid, Divider } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ProductData } from "src/components/Gallery/ProductData";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { ButtonGroup } from 'react-bootstrap';
import { Rating, Stack } from '@mui/material';
import { MDBContainer } from 'mdb-react-ui-kit';
import LongText from './LongText';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import "./product_detail_style.css";
import { Image } from '@mui/icons-material';
import Carousel from "react-elastic-carousel";



import imageShipping from 'src/assets/images/icon_free_shipping.png';
import cashOnDelivery from 'src/assets/images/icon_cod.png';
import productReturn from 'src/assets/images/icon_returns.png';
import isbn from 'src/assets/images/isbn.png';
import calender from 'src/assets/images/calendar.png';
import dimension from 'src/assets/images/dimension.png';
import language from 'src/assets/images/language.png';
import publisher from 'src/assets/images/publisher.png';

const ProductDetailPage = () => {

  const [counter, setCounter] = useState(0);


  const breakPoints = [
    { width: 250, itemsToShow: 1 },
    { width: 450, itemsToShow: 2 },
    { width: 600, itemsToShow: 4 },
    { width: 780, itemsToShow: 4 },
    { width: 950, itemsToShow: 5 },
    { width: 1200, itemsToShow: 6 }
  ]

  const useStyles = makeStyles({
    cardMedia: {
      height: 400,
    },
    addButton: {
      marginTop: 20,
    },
    cart: {
      backgroundColor: "yellow",
    },
  });

  const classes = useStyles();

  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const [product, setProduct] = useState({});



  console.log("Product_id", id);
  useEffect(() => {
    const getProduct = async () => {
      try {
        const product = ProductData.find(x => x.id == id);
        setProduct(product);
        console.log("product:", product);
      } catch (err) {
        console.log(err);
      }
    }
    getProduct();
  }, [id]);

  return (
    <>
      <MDBContainer>
        <Card className="d-flex mt-5 mb-5">
          <Grid container>
            <Grid item xs={12} sm={4}>
              <CardContent style={{ alignItems: "center", alignContent: "center", justifyContent: "center" }}>
                <CardMedia
                  component="img"
                  image={product.image}
                  title={product.title}
                  style={{ maxHeight: "500px", width: "100%", objectFit: "contain" }}
                />
              </CardContent>
              <Stack style={{ marginTop: "15px", paddingLeft: "15px" }} direction="row" spacing={1} className="align-middle align-items-center">
                <Typography variant="h6" component="h2" style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  {product.price}
                </Typography>
                <Typography variant="h6" component="h2" style={{ opacity: "0.8", fontSize: "1rem", color: "#ffc919", fontWeight: "bold" }}>
                  {product.offer}
                </Typography>
              </Stack>
              <Typography variant="h6" component="h2" style={{ opacity: "0.8", fontSize: "1.2rem", paddingLeft: "15px" }} className="text-muted text-decoration-line-through">
                {product.oldPrice}
              </Typography>

              <ButtonGroup size="small" aria-label="small outlined button group" style={{ backgroundColor: "#e0e0e0", marginLeft: "15px", height: "40px", elevation: 0, padding: 0 }}>
                {<Button style={{ color: "#ffc919", fontWeight: "bold", fontSize: "1.4rem" }} disabled={counter <= 0} onClick={() => {
                  setCounter(counter - 1)
                }}>-</Button>}
                {<Button style={{ borderRadius: "0", borderCollapse: "collapse", fontWeight: "bold", fontSize: "1.2rem" }} >{counter}</Button>}
                <Button style={{ color: "#ffc919", fontWeight: "bold", fontSize: "1.4rem" }} disabled={counter >= product['countInStock']} onClick={() => { setCounter(counter + 1) }}>+</Button>
              </ButtonGroup>

              <Stack style={{ marginTop: "15px", paddingLeft: "15px" }} direction="row" spacing={2}>
                <Button variant="contained" startIcon={<ShoppingCartIcon />} className="cart-btn" style={{
                  borderRadius: 5, backgroundColor: "#ffc919", height: "50px", fontWeight: "bold"
                }}>
                  Add to Cart
                </Button>
                <Button variant="outlined" startIcon={<FavoriteBorderIcon />} style={{
                  borderRadius: 5, fontWeight: "bold"
                }}>
                  Wishlist
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={8}>
              <CardContent className='float-left'>

                <Typography variant="h3" component="h3" className="product-title" style={{ fontWeight: "bold" }}>
                  FAMOUS FIVE
                </Typography>

                <Typography variant="caption" component="h6" style={{ fontSize: "1.3rem" }} className="text-muted">
                  by Enid Blyton (Author)
                </Typography>

                <Stack direction="row" className="align-middle align-items-center" style={{ marginBottom: "15px", color: '#ffc919' }}>

                  <Rating readOnly name="half-rating" defaultValue={4.5} precision={0.5} style={{ color: '#ffc919' }} />

                  <Typography variant="caption" style={{ fontSize: "0.9rem" }} className="text-muted ">
                    13 ratings
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={2} className="align-middle text-muted">

                  <p style={{ fontWeight: "bold" }}>Availability :</p>
                  <p style={{ color: "red" }}>In stock</p>

                </Stack>
                <Stack direction="row" spacing={2} className="align-middle text-muted">

                  <p style={{ fontWeight: "bold" }}>Genre :</p>
                  <p style={{ color: "green" }}>Reference</p>

                </Stack>

                <Divider style={{ marginBottom: "15px", height: "1.5px" }} />

                <Typography variant="body1" component="div" className="text-muted mb-2">
                  See all formats and editions
                </Typography>

                <Stack direction="row" spacing={2} className="align-middle">
                  <div className='book-condition'>
                    <h6>BOOK CONDITION</h6>
                    <h5 className="book-condition-text text-muted">New $449.00</h5>
                  </div>
                  <div className='book-condition'>
                    <h6>BOOK BINDING</h6>
                    <h5 className="book-condition-text text-muted">Paper Back $449.00</h5>
                  </div>
                </Stack>

                <Divider style={{ marginBottom: "15px", height: "1.5px", marginTop: "15px" }} />

                <Stack direction="row" spacing={2} className="align-middle">
                  <div className='image-delievery-box'>
                    <img src={imageShipping} alt='free shipping' className='image-delievery' />
                    <h5 className='image-delievery-title'>Free Delivery</h5>
                  </div>
                  <div className='image-delievery-box'>
                    <img src={cashOnDelivery} alt='cash on delivery' className='image-delievery' />
                    <h5 className='image-delievery-title'>Pay on Delivery</h5>
                  </div>
                  <div className='image-delievery-box'>
                    <img src={productReturn} alt='product return' className='image-delievery' />
                    <h5 className='image-delievery-title'>10 days Replacement</h5>
                  </div>
                </Stack>

                <Divider style={{ marginBottom: "15px", height: "1.5px", marginTop: "15px" }} />

                <LongText text="A friendly and inspiring introduction to art history, telling the stories of the world's greatest paintings and artists from prehistory to the modern day The Story of Paintings begins with the cave paintings of our Stone Age ancestors and continues through to the modern day. Mick Manning and Brita Granström take your on a tour of their personally selected gallery which showcases the work of some of the world's most famous artists and few a less well-known ones. The artists featured include van Eyck, da Vinci, Bruegel, Rembrandt, Velázquez, JMW Turner, Van Gogh, Monet, Matisse, Georgia O'Keefe, Picasso, Frida Kahlo and Jackson Pollock as well as Dame Laura Knight and Kalan Khan. The friendly text and illustrations help children to appreciate the art, highlighting interesting biographical details and picking out key details to spot. The book's large format means the art is reproduced on a wonderfully impactful scale. This really is a book to give and treasure. The creative team of Mick Manning and Brita Granström are well-known for their ground breaking children's information books. Their many awards range the TES Information Book Award for What's Under the Bed? and the English Association Non-fiction award for Charlie's War Illustrated." />

                <Divider style={{ marginBottom: "15px", height: "1.5px", marginTop: "15px" }} />


                {/* <Stack direction="row" spacing={1} className="align-middle align-items-center">
                  <Typography variant="h6" component="h2" style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                    {product.price}
                  </Typography>
                  <Typography variant="h6" component="h2" style={{ opacity: "0.8", fontSize: "1rem", color: "#ffc919", fontWeight: "bold" }}>
                    {product.offer}
                  </Typography>
                </Stack>
                <Typography variant="h6" component="h2" style={{ opacity: "0.8", fontSize: "1.2rem" }} className="text-muted text-decoration-line-through">
                  {product.oldPrice}
                </Typography>

                <ButtonGroup size="small" aria-label="small outlined button group" style={{ backgroundColor: "#e0e0e0", height: "40px", elevation: 0, padding: 0 }}>
                  {<Button style={{ color: "#ffc919", fontWeight: "bold", fontSize: "1.4rem" }} disabled={counter <= 0} onClick={() => {
                    setCounter(counter - 1)
                  }}>-</Button>}
                  {<Button style={{ borderRadius: "0", borderCollapse: "collapse", fontWeight: "bold", fontSize: "1.2rem" }} >{counter}</Button>}
                  <Button style={{ color: "#ffc919", fontWeight: "bold", fontSize: "1.4rem" }} disabled={counter >= product['countInStock']} onClick={() => { setCounter(counter + 1) }}>+</Button>
                </ButtonGroup> */}

                {/* <Divider style={{ marginBottom: "15px", height: "1.5px", marginTop: "15px" }} /> */}

                <Carousel
                  breakPoints={breakPoints}>
                  {/* <Stack direction="row" spacing={2} className="align-middle"> */}
                  <div className='book-detail-attribute-box'>
                    <h5 className='image-delievery-title'>Language</h5>
                    <img src={language} alt='free shipping' className='book-detail-attribute-image' />
                    <h5 className='book-detail-title-sub'>English</h5>
                  </div>
                  <div className='book-detail-attribute-box'>
                    <h5 className='image-delievery-title'>Dimensions</h5>
                    <img src={dimension} alt='cash on delivery' className='book-detail-attribute-image' />
                    <h5 className='book-detail-title-sub'>20.3 x 25.4 x 4.7 cm</h5>
                  </div>
                  <div className='book-detail-attribute-box'>
                    <h5 className='image-delievery-title'>Publisher</h5>
                    <img src={publisher} alt='product return' className='book-detail-attribute-image' />
                    <h5 className='book-detail-title-sub'>HODDER CHILDREN'S BOOKS</h5>
                  </div>
                  <div className='book-detail-attribute-box'>
                    <h5 className='image-delievery-title'>Publication date</h5>
                    <img src={calender} alt='product return' className='book-detail-attribute-image' />
                    <h5 className='book-detail-title-sub'>15 July 2004</h5>
                  </div>
                  <div className='book-detail-attribute-box'>
                    <h5 className='image-delievery-title'>ISBN-10</h5>
                    <img src={isbn} alt='product return' className='book-detail-attribute-image' />
                    <h5 className='book-detail-title-sub'>0340894547</h5>
                  </div>
                  <div className='book-detail-attribute-box'>
                    <h5 className='image-delievery-title'>ISBN-13</h5>
                    <img src={isbn} alt='product return' className='book-detail-attribute-image' />
                    <h5 className='book-detail-title-sub'>978-0340894545</h5>
                  </div>
                  {/* </Stack> */}
                </Carousel>

                <Divider style={{ marginBottom: "15px", height: "1.5px", marginTop: "15px" }} />




                {/* <Stack style={{ marginTop: "15px" }} direction="row" spacing={2}>
                  <Button variant="contained" startIcon={<ShoppingCartIcon />} className="cart-btn" style={{
                    borderRadius: 5, backgroundColor: "#ffc919", height: "50px", fontWeight: "bold"
                  }}>
                    Add to Cart
                  </Button>
                  <Button variant="outlined" startIcon={<FavoriteBorderIcon />} style={{
                    borderRadius: 5, fontWeight: "bold"
                  }}>
                    Wishlist
                  </Button>
                </Stack> */}

              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </MDBContainer>

    </>

  );
};

export default ProductDetailPage;
